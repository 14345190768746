import React from 'react';
import FingoCircularProgressLoader from '@fingo/lib/components/loader/FingoCircularProgressLoader';
import { useGetUser } from '@fingo/lib/hooks';
import { useHistory } from 'react-router-dom';
import { hasRequestingPlatformAcceptedTerms } from '../../helpers/credentials';
import useGetRequestingPlatform from '../../hooks/useGetRequestingPlatform';

const CheckCredentialsRoute = () => {
  const history = useHistory();
  const requestingPlatform = useGetRequestingPlatform();
  const user = useGetUser();

  if (user && requestingPlatform) {
    if (user.selectedCompany?.hasSiiCredentials
      && hasRequestingPlatformAcceptedTerms(user.selectedCompany, requestingPlatform)
    ) {
      history.replace({
        pathname: '/invoices',
      });
    } else {
      history
        .replace({
          pathname: '/home',
        });
    }
  }

  return <FingoCircularProgressLoader />;
};

export default CheckCredentialsRoute;
