import React from 'react';
import { Link } from 'react-router-dom';
import { useDynamicFingoLogo } from '@fingo/lib/hooks';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

const NavBar = () => {
  const fingoLogo = useDynamicFingoLogo();
  return (
    <AppBar
      elevation={0}
      position="sticky"
      color="common.white"
      sx={{ minHeight: 55, justifyContent: 'center' }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box style={{ cursor: 'pointer' }} component={Link} to="/">
          <img src={fingoLogo} alt="logo" width="120px" />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
