import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIsMobile } from '@fingo/lib/hooks';
import DesktopCredentialsCard from './DesktopCredentialsCard';
import MobileCredentialsCard from './MobileCredentialsCard';

const CredentialsCard = ({ DialogComponent, toggle, ...props }) => {
  const { credentials } = props;
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const CredentialCardComponent = isMobile
    ? MobileCredentialsCard
    : DesktopCredentialsCard;
  const formatedCredentials = credentials.map(({ key, value }) => ({
    value: value.name || value,
    key,
  }));

  return (
    <>
      <CredentialCardComponent
        {...props}
        toggle={() => {
          if (toggle) {
            toggle();
          } else {
            setOpen(!open);
          }
        }}
        credentials={formatedCredentials}
      />
      {DialogComponent && <DialogComponent open={open} setOpen={setOpen} />}
    </>
  );
};

CredentialsCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  DialogComponent: PropTypes.func,
  toggle: PropTypes.func,
  credentials: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

CredentialsCard.defaultProps = {
  title: '',
  subtitle: '',
  DialogComponent: null,
  toggle: null,
};

export default CredentialsCard;
