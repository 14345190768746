import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { TrackerProvider } from '@fingo/lib/components/tracker';
import ShoppingCart from '../shopping-carts/ShoppingCart';
import createCustomTheme from '../../theme';
import NavBar from './Navbar';
import Tabs from './Tabs';
import useGetRequestingPlatform from '../../hooks/useGetRequestingPlatform';

const AppWrapper = ({ children }) => {
  const { themeConfig } = useParams();
  const theme = createCustomTheme(themeConfig);
  useGetRequestingPlatform();

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TrackerProvider frontendName="factoring-widget">
          <CssBaseline />
          <Container variant="body" maxWidth={false}>
            <NavBar />
            <Tabs />
            <ShoppingCart />
            {children}
          </Container>
        </TrackerProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppWrapper;
