import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from '@fingo/lib/components/router/PrivateRoute';
import FingoCircularProgressLoader from '@fingo/lib/components/loader/FingoCircularProgressLoader';
import NationalTaxServiceCredentialsDialog from '@fingo/lib/components/dialogs/NationalTaxServiceCredentialDialog';
import AppWrapper from './components/layout/AppWrapper';
import { Cessions, Credentials, Home, Invoices, Offers } from './pages';
import CheckCredentialsRoute from './components/layout/CheckCredentialsRoute';
import useWidgetAuth from './hooks/useWidgetAuth';

const App = () => {
  const isLoading = useWidgetAuth();

  if (isLoading) return <FingoCircularProgressLoader />;

  return (
    <Router>
      <AppWrapper>
        <NationalTaxServiceCredentialsDialog />
        <Switch>
          <Route exact path="/" component={CheckCredentialsRoute} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute path="/invoices" component={Invoices} />
          <PrivateRoute path="/offers" component={Offers} />
          <PrivateRoute path="/cessions" component={Cessions} />
          <PrivateRoute exact path="/credentials" component={Credentials} />
        </Switch>
      </AppWrapper>
    </Router>
  );
};

export default App;
